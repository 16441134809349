/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * comma-separated list of sortable fields
 */
export enum SortFields {
  NAME = 'name',
  GOAL = 'goal',
  PLEDGED_AMOUNT = 'pledged_amount',
  STATUS = 'status',
  DONATION_COUNT = 'donation_count',
  FUNDED_PERCENTAGE = 'funded_percentage',
  CREATED_DATE = 'created_date',
  START_DATE = 'start_date',
  LAUNCH_DATE = 'launch_date',
  END_DATE = 'end_date',
}
