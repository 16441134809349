/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CampaignOrCommunity } from '../models/CampaignOrCommunity';
import type { CategoryAggregateResponse } from '../models/CategoryAggregateResponse';
import type { CharityLookupResponse } from '../models/CharityLookupResponse';
import type { Empty } from '../models/Empty';
import type { PagingResponse } from '../models/PagingResponse';
import type { StatusAggregateResponse } from '../models/StatusAggregateResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class DefaultService {
  /**
   * Discovery Search
   * Implements the search API of the /discover page
   * @param entity Entity Type to Query for
   * @param authorization User Token (Bearer XXX)
   * @param q Query String to search against
   * @param charityType comma-separated list of nonprofit types, at least one must match.
   * @param categories comma-separated list of categories, at least one must match.
   * @param limit Maximum results to return
   * @param offset Offset into results to start at
   * @param searchAfter For use in pagination: sent in subsequent requests, using value from previous pagination results.
   *
   * @param sort Sort order (badly named; sort and filter)
   * @param zakatVerified If present, zakat verified status must match this parameter (AND)
   * @param hideCommunityCampaigns If present and true, hides the community campaigns (AND)
   * @param nearMe Latitude, Longitude, Distance in km (AND)
   * @param countries ISO3166 2 letter country codes (AND)
   * @param aggregateCategories If true, will aggregate and return categories with counts
   * @param canChooseInCampaignCreation If true, only show communities that can have campaigns created under them.
   * @returns any Campaign/Community Search Response
   * @throws ApiError
   */
  public static getDiscover(
    entity: 'campaign' | 'organization' | 'community',
    authorization?: string,
    q?: string,
    charityType?: '501c3' | 'gift_aid' | 'registered_charity',
    categories?:
      | 'food-water'
      | 'mosque-community'
      | 'education'
      | 'women'
      | 'orphans'
      | 'refugee'
      | 'emergency-relief'
      | 'health'
      | 'creative'
      | 'other',
    limit?: number,
    offset?: number,
    searchAfter?: string,
    sort?: 'almost_funded' | 'newest' | 'needs_love' | 'ending_soon',
    zakatVerified?: boolean,
    hideCommunityCampaigns?: boolean,
    nearMe?: string,
    countries?: string,
    aggregateCategories?: boolean,
    canChooseInCampaignCreation?: boolean,
  ): CancelablePromise<{
    results: Array<CampaignOrCommunity>;
    paging: PagingResponse;
    statuses?: StatusAggregateResponse;
    categories?: CategoryAggregateResponse;
    max_score?: number;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/discover/{entity}',
      path: {
        entity: entity,
      },
      headers: {
        Authorization: authorization,
      },
      query: {
        q: q,
        charity_type: charityType,
        categories: categories,
        limit: limit,
        offset: offset,
        search_after: searchAfter,
        sort: sort,
        zakat_verified: zakatVerified,
        hide_community_campaigns: hideCommunityCampaigns,
        near_me: nearMe,
        countries: countries,
        aggregate_categories: aggregateCategories,
        can_choose_in_campaign_creation: canChooseInCampaignCreation,
      },
    });
  }
  /**
   * Featured Campaigns/Communities
   * @param entity Entity Type to Query for
   * @param authorization User Token (Bearer XXX)
   * @param limit Maximum results to return
   * @param offset Offset into results to start at
   * @param randomize Return results in weighted order
   * @param type Featured List Type
   * @returns any Campaign/Community Search Response
   * @throws ApiError
   */
  public static getCardsFeatured(
    entity: 'campaign' | 'organization' | 'community',
    authorization?: string,
    limit?: number,
    offset?: number,
    randomize?: boolean,
    type?: 'general' | 'ramadan' | 'dhulhijah' | 'zakat',
  ): CancelablePromise<{
    results: Array<CampaignOrCommunity>;
    paging: PagingResponse;
    statuses?: StatusAggregateResponse;
    categories?: CategoryAggregateResponse;
    max_score?: number;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/cards/{entity}/featured',
      path: {
        entity: entity,
      },
      headers: {
        Authorization: authorization,
      },
      query: {
        limit: limit,
        offset: offset,
        randomize: randomize,
        type: type,
      },
    });
  }
  /**
   * Get campaign/comunity by list id.
   * @param entity Entity Type to Query for
   * @param id Entity ID
   * @param authorization User Token (Bearer XXX)
   * @param limit Maximum results to return
   * @param offset Offset into results to start at
   * @param randomize Return results in weighted order
   * @returns any Campaign/Community Search Response
   * @throws ApiError
   */
  public static getCardsByListId(
    entity: 'campaign' | 'organization' | 'community',
    id: number,
    authorization?: string,
    limit?: number,
    offset?: number,
    randomize?: boolean,
  ): CancelablePromise<{
    results: Array<CampaignOrCommunity>;
    paging: PagingResponse;
    statuses?: StatusAggregateResponse;
    categories?: CategoryAggregateResponse;
    max_score?: number;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/cards/{entity}/by-list-id/{id}',
      path: {
        entity: entity,
        id: id,
      },
      headers: {
        Authorization: authorization,
      },
      query: {
        limit: limit,
        offset: offset,
        randomize: randomize,
      },
    });
  }
  /**
   * Campaigns/Communities belonging to account id
   * Campaigns/Communities belonging to account id.
   * Authenticated users can see more info for their own resources (and admins can see all).
   *
   * @param entity Entity Type to Query for
   * @param id Entity ID
   * @param authorization User Token (Bearer XXX)
   * @param limit Maximum results to return
   * @param offset Offset into results to start at
   * @param campaignStatus Campaign must be in one of these statuses
   * @param sort comma-separated list of sortable fields
   * @param sortDirection comma-separated list of 'asc' or 'desc' corresponding to sort fields (default 'asc')
   * @param q Query String to search against
   * @param searchAfter For use in pagination: sent in subsequent requests, using value from previous pagination results.
   *
   * @param settlementCurrency If present, will only show campaigns or communities with this field.
   * @param aggregateStatuses If true, will return status aggregation based on this query.
   * @returns any Campaign/Community Search Response
   * @throws ApiError
   */
  public static getCardsByAccountId(
    entity: 'campaign' | 'organization' | 'community',
    id: number,
    authorization?: string,
    limit?: number,
    offset?: number,
    campaignStatus?:
      | 'not_submitted'
      | 'pending_approval'
      | 'suspended'
      | 'active'
      | 'completed'
      | 'failed'
      | 'approved'
      | 'in_review'
      | 'died'
      | 'rejected'
      | 'complete_unpaid',
    sort?:
      | 'name'
      | 'goal'
      | 'pledged_amount'
      | 'status'
      | 'donation_count'
      | 'funded_percentage'
      | 'created_date'
      | 'start_date'
      | 'launch_date'
      | 'end_date',
    sortDirection?: 'asc' | 'desc',
    q?: string,
    searchAfter?: string,
    settlementCurrency?: 'USD' | 'CAD' | 'GBP',
    aggregateStatuses?: boolean,
  ): CancelablePromise<{
    results: Array<CampaignOrCommunity>;
    paging: PagingResponse;
    statuses?: StatusAggregateResponse;
    categories?: CategoryAggregateResponse;
    max_score?: number;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/cards/{entity}/by-account-id/{id}',
      path: {
        entity: entity,
        id: id,
      },
      headers: {
        Authorization: authorization,
      },
      query: {
        limit: limit,
        offset: offset,
        campaign_status: campaignStatus,
        sort: sort,
        sort_direction: sortDirection,
        q: q,
        search_after: searchAfter,
        settlement_currency: settlementCurrency,
        aggregate_statuses: aggregateStatuses,
      },
    });
  }
  /**
   * retrieve a campaign/community by its id
   * @param entity Entity Type to Query for
   * @param id Entity ID
   * @param authorization User Token (Bearer XXX)
   * @returns any Campaign/Community Search Response
   * @throws ApiError
   */
  public static getCardsById(
    entity: 'campaign' | 'organization' | 'community',
    id: number,
    authorization?: string,
  ): CancelablePromise<{
    results: Array<CampaignOrCommunity>;
    paging: PagingResponse;
    statuses?: StatusAggregateResponse;
    categories?: CategoryAggregateResponse;
    max_score?: number;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/cards/{entity}/by-id/{id}',
      path: {
        entity: entity,
        id: id,
      },
      headers: {
        Authorization: authorization,
      },
    });
  }
  /**
   * retrieve multiple campaigns/communities from a list of ids
   * @param entity Entity Type to Query for
   * @param authorization User Token (Bearer XXX)
   * @param idList comma separated list of entity ids
   * @returns any Campaign/Community Search Response
   * @throws ApiError
   */
  public static getCardsByIdList(
    entity: 'campaign' | 'organization' | 'community',
    authorization?: string,
    idList?: string,
  ): CancelablePromise<{
    results: Array<CampaignOrCommunity>;
    paging: PagingResponse;
    statuses?: StatusAggregateResponse;
    categories?: CategoryAggregateResponse;
    max_score?: number;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/cards/{entity}/by-id-list',
      path: {
        entity: entity,
      },
      headers: {
        Authorization: authorization,
      },
      query: {
        id_list: idList,
      },
    });
  }
  /**
   * Search charities
   * Search charities by name, and optionally filtered by country
   * @param q Query String
   * @param authorization User Token (Bearer XXX)
   * @param country Country Code
   * @returns CharityLookupResponse Charity Lookup Response
   * @throws ApiError
   */
  public static getCharityLookup(
    q: string,
    authorization?: string,
    country?: string,
  ): CancelablePromise<CharityLookupResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/charity/lookup',
      headers: {
        Authorization: authorization,
      },
      query: {
        q: q,
        country: country,
      },
    });
  }
  /**
   * Returns the basic system status
   * @returns Empty System Status Response
   * @throws ApiError
   */
  public static getSystemStatus(): CancelablePromise<Empty> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/system/status',
    });
  }
}
