/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * comma-separated list of categories, at least one must match.
 */
export enum Categories {
  FOOD_WATER = 'food-water',
  MOSQUE_COMMUNITY = 'mosque-community',
  EDUCATION = 'education',
  WOMEN = 'women',
  ORPHANS = 'orphans',
  REFUGEE = 'refugee',
  EMERGENCY_RELIEF = 'emergency-relief',
  HEALTH = 'health',
  CREATIVE = 'creative',
  OTHER = 'other',
}
