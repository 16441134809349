/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RecurringPlansSubscribers } from './RecurringPlansSubscribers';
export type PaginatedRecurringPlansSubscribers = {
  /**
   * Number of records to take
   */
  take?: number;
  /**
   * Number of records to skip
   */
  skip?: number;
  /**
   * Order by asc or desc
   */
  orderBy?: PaginatedRecurringPlansSubscribers.orderBy;
  /**
   * Filter by status, either active or in-active
   */
  status?: PaginatedRecurringPlansSubscribers.status;
  data: Array<RecurringPlansSubscribers>;
  count: number;
};
export namespace PaginatedRecurringPlansSubscribers {
  /**
   * Order by asc or desc
   */
  export enum orderBy {
    ASC = 'asc',
    DESC = 'desc',
  }
  /**
   * Filter by status, either active or in-active
   */
  export enum status {
    ACTIVE = 'active',
    INACTIVE = 'inactive',
  }
}
