/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * comma-separated list of 'asc' or 'desc' corresponding to sort fields (default 'asc')
 */
export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
}
