/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * comma-separated list of nonprofit types, at least one must match.
 */
export enum CharityType {
  _501C3 = '501c3',
  GIFT_AID = 'gift_aid',
  REGISTERED_CHARITY = 'registered_charity',
}
