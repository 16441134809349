/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Campaign must be in one of these statuses
 */
export enum CampaignStatus {
  NOT_SUBMITTED = 'not_submitted',
  PENDING_APPROVAL = 'pending_approval',
  SUSPENDED = 'suspended',
  ACTIVE = 'active',
  COMPLETED = 'completed',
  FAILED = 'failed',
  APPROVED = 'approved',
  IN_REVIEW = 'in_review',
  DIED = 'died',
  REJECTED = 'rejected',
  COMPLETE_UNPAID = 'complete_unpaid',
}
