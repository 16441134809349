/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export { ApiError } from './core/ApiError';
export { CancelablePromise, CancelError } from './core/CancelablePromise';
export { OpenAPI } from './core/OpenAPI';
export type { OpenAPIConfig } from './core/OpenAPI';

export type { Account } from './models/Account';
export type { AccountArray } from './models/AccountArray';
export type { AnyEntity } from './models/AnyEntity';
export type { Campaign } from './models/Campaign';
export type { CampaignArray } from './models/CampaignArray';
export type { CampaignOrCommunity } from './models/CampaignOrCommunity';
export { CampaignStatus } from './models/CampaignStatus';
export { Categories } from './models/Categories';
export type { CategoryAggregateResponse } from './models/CategoryAggregateResponse';
export type { CharityLookupResponse } from './models/CharityLookupResponse';
export { CharityType } from './models/CharityType';
export type { Community } from './models/Community';
export type { CommunityArray } from './models/CommunityArray';
export type { Empty } from './models/Empty';
export { Entity } from './models/Entity';
export { Environment } from './models/Environment';
export type { Id } from './models/Id';
export type { Limit } from './models/Limit';
export type { Offset } from './models/Offset';
export type { PagingResponse } from './models/PagingResponse';
export type { QueryString } from './models/QueryString';
export type { Randomize } from './models/Randomize';
export type { SearchAfter } from './models/SearchAfter';
export { SortDirection } from './models/SortDirection';
export { SortFields } from './models/SortFields';
export type { StatusAggregateResponse } from './models/StatusAggregateResponse';
export type { UserAuthenticationHeader } from './models/UserAuthenticationHeader';

export { DefaultService } from './services/DefaultService';
